<template>
  <div class="machine-main">
    <!-- 人工监测 -->
    <div class="first-content">
      <div class="div-line-veto">
        <div class="card-message-icon"></div>
        <div>单项否决</div>
      </div>
      <div class="message-div1"></div>
    </div>
    <div class="second-content">
      <div class="div-line-veto">
        <div class="card-message-icon"></div>
        <div>扣分指标</div>
      </div>
      <div class="message-div2"></div>
    </div>
    <div class="second-content">
      <div class="div-line-veto">
        <div class="card-message-icon"></div>
        <div>加分指标</div>
      </div>
      <div class="message-div3"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.machine-main {
  width: 100%;
  height: 100%;
}
.first-content {
  width: 100%;
  background-color: #fff;
  padding: 20px;
}
.second-content {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
}
.message-div1 {
  width: 1030px;
  height: 90px;
  background: url('~@/assets/message1.png') center center no-repeat;
  background-size: 100% 100%;
}
.message-div2 {
  width: 1030px;
  height: 1010px;
  background: url('~@/assets/message2.png') center center no-repeat;
  background-size: 100% 100%;
}
.message-div3 {
  width: 1030px;
  height: 750px;
  background: url('~@/assets/message3.png') center center no-repeat;
  background-size: 100% 100%;
}
</style>