<template>
  <div class="main-container" style="padding-bottom: 20px">
    <div class="main-header">
      <div class="main-header-top">
        <div class="icon-left"></div>
        <div class="icon-right">网站基本信息</div>
      </div>
      <el-row :gutter="24">
        <el-form
          ref="elForm"
          :model="formData"
          :rules="rules"
          size="mini"
          label-position="top"
          :disabled="$route.query.type === 'detail'"
        >
          <el-col :span="10">
            <el-form-item label="机构名称" prop="sitesOrgArr">
              <el-cascader
                style="width: 100%"
                v-model="formData.sitesOrgArr"
                :options="orgTreeData"
                placeholder="请选择机构"
                clearable
                filterable
                :props="{
                  expandTrigger: 'hover',
                  label: 'agencyName',
                  value: 'agencyName',
                  checkStrictly: true,
                }"
                ref="sitesOrg"
                @change="changeSite($event)"
                @blur="blurSite"
                :disabled="$route.query.type === 'edit'"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="网站名称" prop="siteName">
              <el-input
                v-model="formData.siteName"
                placeholder="请输入"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="订单类型" prop="orderType">
              <el-select
                v-model="formData.orderType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="(item, index) in orderTypeList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="开始和结束时间" prop="CheckTimeSelector">
              <el-date-picker
                type="daterange"
                v-model="formData.CheckTimeSelector"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                start-placeholder="请选择开始日期"
                end-placeholder="请选择结束日期"
                range-separator="至"
                clearable
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="网站首页地址" prop="siteUrl">
              <el-input
                v-model="formData.siteUrl"
                placeholder="请输入"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <div class="main-content">
      <el-tabs v-model="activeName">
        <el-tab-pane label="机器监测" name="first">
          <div class="machine-main">
            <!-- 机器监测 -->
            <divLineVeto> </divLineVeto>
            <div class="first-content">
              <div
                class="underline-div"
                v-for="(item, index) in treeList1"
                :key="index"
              >
                <div class="title-div">{{ item.standardContent }}</div>
                <div v-for="(it, i) in item.children" :key="i">
                  <div>{{ it.standardContent }}</div>
                  <div class="button-div">
                    <!-- <el-button
                      type="primary"
                      @click="openSelectColumn(it.id)"
                      v-if="it.dataType"
                    >
                      选择栏目
                    </el-button> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="second-content">
              <div class="div-line-veto">
                <div class="card-message-icon"></div>
                <div>扣分指标</div>
              </div>
              <div class="message-div6"></div>
              <!-- <div class="round">一级指标：发布解读</div> -->
              <!-- <div class="table-css">
                <el-table
                  :data="tableData1"
                  :span-method="objectSpanMethod1"
                  border
                  :header-cell-style="{ background: '#D0D5E7' }"
                  :cell-class-name="cellClassFn"
                  style="width: 90%; margin-top: 20px"
                >
                  <el-table-column prop="title" label="二级指标" width="120">
                  </el-table-column>
                  <el-table-column prop="standardContent" label="细则">
                  </el-table-column>
                  <el-table-column prop="amount1" label="查看栏目" width="180">
                    <template slot-scope="scope">
                      <el-button
                        v-if="scope.row.dataType === 1"
                        type="text"
                        size="small"
                        @click="viewTable(scope.row.id)"
                        >查看</el-button
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="amount2" label="操作" width="180">
                    <template slot-scope="scope">
                      <div
                        v-if="scope.row.dataType === 1"
                        style="display: flex"
                      >
                        <el-radio
                          v-model="scope.row.standardData"
                          label="true"
                          :disabled="$route.query.type === 'detail'"
                          >有</el-radio
                        >
                        <el-radio
                          v-model="scope.row.standardData"
                          label="false"
                          :disabled="$route.query.type === 'detail'"
                          >无</el-radio
                        >
                      </div>
                      <el-button
                        v-if="
                          scope.row.dataType === 2 &&
                          tableData1[scope.$index - 1].standardData !== 'false'
                        "
                        type="text"
                        size="small"
                        @click="openSelectColumn(scope.row.id)"
                        >选择</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="round" style="margin-top: 20px">
                一级指标：功能设计
              </div>
              <div class="table-css">
                <el-table
                  :data="tableData3"
                  :span-method="objectSpanMethod1"
                  border
                  :header-cell-style="{ background: '#D0D5E7' }"
                  :cell-class-name="cellClassFn"
                  style="width: 90%; margin-top: 20px"
                >
                  <el-table-column prop="title" label="二级指标" width="120">
                  </el-table-column>
                  <el-table-column prop="standardContent" label="细则">
                  </el-table-column>
                  <el-table-column prop="amount1" label="查看栏目" width="180">
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        size="small"
                        @click="viewTable(scope.row.id)"
                        >查看</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div> -->
            </div>
            <div class="second-content">
              <div class="div-line-veto">
                <div class="card-message-icon"></div>
                <div>加分指标</div>
              </div>
              <div class="message-div7"></div>
              <!-- <div class="round">一级指标：信息发布</div>
              <div>
                <el-table
                  :data="tableData2"
                  :span-method="objectSpanMethod2"
                  border
                  :header-cell-style="{ background: '#D0D5E7' }"
                  :cell-class-name="cellClassFn"
                  style="width: 90%; margin-top: 20px"
                >
                  <el-table-column prop="title" label="二级指标" width="120">
                  </el-table-column>
                  <el-table-column prop="standardContent" label="细则">
                  </el-table-column>
                  <el-table-column prop="amount1" label="查看栏目" width="180">
                    <template slot-scope="scope">
                      <el-button
                        v-if="scope.row.dataType === 1"
                        type="text"
                        size="small"
                        @click="viewTable(scope.row.id)"
                        >查看</el-button
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="amount2" label="操作" width="180">
                    <template slot-scope="scope">
                      <div
                        v-if="scope.row.dataType === 1"
                        style="display: flex"
                      >
                        <el-radio
                          v-model="scope.row.standardData"
                          label="true"
                          :disabled="$route.query.type === 'detail'"
                          >有</el-radio
                        >
                        <el-radio
                          v-model="scope.row.standardData"
                          label="false"
                          :disabled="$route.query.type === 'detail'"
                          >无</el-radio
                        >
                      </div>
                      <el-button
                        v-if="scope.row.dataType === 2"
                        type="text"
                        size="small"
                        @click="openSelectColumn(scope.row.id)"
                        >选择</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div> -->
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="机器 + 人工监测" name="second">
          <div class="machine-main">
            <!-- 机器+人工监测 -->
            <divLineVeto> </divLineVeto>
            <div class="first-content">
              <!-- 单项否决 -->
              <div
                class="underline-div"
                v-for="(item, index) in treeList2"
                :key="index"
              >
                <div class="title-div">{{ item.standardContent }}</div>
                <div v-for="(it, i) in item.children" :key="i">
                  <div>{{ it.standardContent }}</div>
                  <div class="button-div">
                    <!-- <el-button type="primary" @click="openSelectColumn(it.id)">
                      选择栏目
                    </el-button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="人工监测" name="third"><labour /></el-tab-pane>
        <el-tab-pane label="无需监测" name="fourth"
          ><noMonitoring
        /></el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      :title="
        dialogType === 'edit'
          ? '编辑栏目'
          : dialogType === 'view'
          ? '查看栏目'
          : '新增栏目'
      "
      :visible.sync="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      @close="cancelClick"
      width="40%"
    >
      <div class="dialog-content">
        <el-table
          :data="tableData"
          ref="multipleTable"
          style="width: 100%; margin-bottom: 20px"
          height="350"
          border
          row-key="id"
          :tree-props="{
            children: 'children',
            hasChildren: 'hasChildren',
            checked: 'checked',
          }"
          default-expand-all
          header-cell-class-name="table-header"
          @selection-change="selectionChange"
          @select-all="selectAll"
        >
          >
          <el-table-column
            type="selection"
            width="55"
            :selectable="handleDisable"
          >
          </el-table-column>
          <el-table-column
            prop="channelName"
            label="栏目名称"
            sortable
            width="180"
          >
          </el-table-column>
          <el-table-column prop="channelUrl" label="网站地址" sortable>
          </el-table-column>
        </el-table>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        v-if="$route.query.type != 'detail' && dialogType != 'view'"
      >
        <el-button size="small" @click="cancelClick">取 消</el-button>
        <el-button size="small" type="primary" @click="saveOnClick"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div style="position: fixed; bottom: 0; right: 40%">
      <el-button
        size="small"
        type="primary"
        @click="submitForm('elForm')"
        v-if="$route.query.type != 'detail'"
        >提 交</el-button
      >
    </div>
  </div>
</template>

<script>
import divLineVeto from './components/divLineVeto.vue';
import labour from './components/labour.vue';
import noMonitoring from './components/noMonitoring.vue';

export default {
  components: {
    divLineVeto,
    labour,
    noMonitoring,
  },
  props: [],
  data() {
    var checkOrderEndTime = (rule, value, callback) => {
      const currentDate = new Date();
      setTimeout(() => {
        if (new Date(this.formData.orderEndTime) < currentDate) {
          callback(new Error('订单结束时间不可早于或等于今天'));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      formData: {
        orderCode: '', //订单号，修改订单时必填
        agencyName: '', //机构名称
        agencyId: null, //机构ID
        agencyCode: '',
        siteName: '', //网站名称
        orderType: '2000', //订单类型：1000-试用订单，2000-正式订单
        orderStartTime: '', //订单开始时间
        orderEndTime: '', //订单结束时间
        siteUrl: '', //网站地址
        sitesOrgArr: [],
        CheckTimeSelector: [],
        standardDtoList: [],
      },
      orgTreeData: [],
      orderTypeList: [
        { label: '正式订单', value: '2000' },
        { label: '试用订单', value: '1000' },
      ],
      activeName: 'first',
      dialogVisible: false,
      dialogType: 'add',
      // 栏目数据
      tableData: [],
      // 选中的表格
      multipleSelection: [],
      // 处理树数组的临时储存
      objectArray: [],
      // 所以选择项的id校验用
      // allIdArr1: [],
      findId: null,
      rules: {
        sitesOrgArr: [
          {
            required: true,
            message: '请选择机构名称',
            trigger: 'change',
          },
        ],
        siteName: [
          {
            required: true,
            message: '请输入网站名称',
            trigger: 'blur',
          },
        ],
        orderType: [
          {
            required: true,
            message: '请选择订单类型',
            trigger: 'change',
          },
        ],
        CheckTimeSelector: [
          {
            required: true,
            message: '日期范围不能为空',
            trigger: 'blur',
          },
          { validator: checkOrderEndTime, trigger: 'blur' },
        ],
        siteUrl: [
          {
            required: true,
            message: '请输入网站地址',
            trigger: 'blur',
          },
        ],
      },
      treeList1: [],
      // tableData1: [],
      // tableData2: [],
      // tableData3: [],
      treeList2: [],
      // treeList3: [],
    };
  },
  computed: {},
  watch: {
    'formData.CheckTimeSelector'() {
      if (
        this.formData.CheckTimeSelector &&
        this.formData.CheckTimeSelector.length > 0
      ) {
        this.formData.orderStartTime = this.formData.CheckTimeSelector[0];
        this.formData.orderEndTime = this.formData.CheckTimeSelector[1];
      } else {
        this.formData.orderStartTime = '';
        this.formData.orderEndTime = '';
      }
    },
  },
  created() {},
  async mounted() {
    await this.getTreeList();
    await this.getTableData();
    await this.getOrderDetail(1);
    if (this.$route.query.orderCode) {
      // 修改或详情
      await this.getOrderDetail(3, '1,3');
    } else {
      await this.getOrderDetail(3);
    }
    if (this.$route.query.type != 'detail') {
      this.dialogType = 'view';
    }
  },
  methods: {
    // 选择机构触发事件
    changeSite(e) {
      const curNode = this.$refs['sitesOrg'].getCheckedNodes();
      if (curNode.length !== 0) {
        this.formData.agencyName = curNode[0].data.agencyName || '';
        this.formData.agencyId = curNode[0].data.agencyId || '';
        this.formData.agencyCode = curNode[0].data.agencyCode || '';
      }
    },
    blurSite() {
      this.$refs.elForm.validateField('sitesOrgArr');
    },
    //加载机构树
    async getTreeList() {
      let res = await this.$api.operationOrder.getTreeList();
      if (res.data) {
        this.orgTreeData = res.data;
      }
    },
    //机构树回显
    search(object, value) {
      for (let key in object) {
        if (object[key].agencyName == value) return [object[key].agencyName];
        if (
          object[key].children &&
          Object.keys(object[key].children).length > 0
        ) {
          var temp = this.search(object[key].children, value);
          if (temp) return [object[key].agencyName, temp].flat();
        }
      }
    },
    objectSpanMethod1({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    objectSpanMethod2({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    cellClassFn({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return 'grey';
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // let isEqual;
          // if (!isEqual) {
          // this.tableData1.forEach((item) => {
          //   if (item.standardData === 'true' || item.standardData === 'false') {
          //     let obj1 = {
          //       standardId: item.id,
          //       standardData: item.standardData,
          //     };
          //     const reIndex = this.formData.standardDtoList.findIndex(
          //       (obj) => obj.standardId === item.id
          //     );
          //     if (reIndex !== -1) {
          //       // 存在
          //       this.$set(this.formData.standardDtoList, reIndex, obj1);
          //     } else {
          //       // 不存在
          //       this.formData.standardDtoList.push(obj1);
          //     }
          //     const reIndex1 = this.formData.standardDtoList.findIndex(
          //       (obj) => obj.standardId === item.id + 1
          //     );
          //     // const reIndex2 = this.allIdArr1.findIndex(
          //     //   (obj) => obj === item.id + 1
          //     // );
          //     if (obj1.standardData === 'false') {
          //       if (reIndex1 !== -1) {
          //         this.formData.standardDtoList.splice(reIndex1, 1);
          //       }
          //       // if (reIndex2 !== -1) {
          //       //   this.allIdArr1.splice(reIndex2, 1);
          //       // }
          //     }
          //     // else {
          //     //   if (reIndex2 === -1) {
          //     //     this.allIdArr1.push(obj1.standardId + 1);
          //     //   }
          //     // }
          //   }
          // });
          // this.tableData2.forEach((item) => {
          //   if (item.standardData === 'true' || item.standardData === 'false') {
          //     let obj1 = {
          //       standardId: item.id,
          //       standardData: item.standardData,
          //     };
          //     const reIndex = this.formData.standardDtoList.findIndex(
          //       (obj) => obj.standardId === item.id
          //     );
          //     if (reIndex !== -1) {
          //       this.$set(this.formData.standardDtoList, reIndex, obj1);
          //     } else {
          //       this.formData.standardDtoList.push(obj1);
          //     }
          //   }
          // });
          // }
          // let allIdArr2 = this.formData.standardDtoList
          //   .map((obj) => obj.standardId)
          //   .sort();
          // this.allIdArr1 = this.allIdArr1.sort();
          // isEqual =
          //   this.allIdArr1.length === allIdArr2.length &&
          //   this.allIdArr1.every((value, index) => value === allIdArr2[index]);
          this.formData.standardDtoList = this.formData.standardDtoList.map(
            (item) => {
              if (Array.isArray(item.standardData)) {
                return {
                  standardId: item.standardId,
                  standardData: item.standardData.map((i) => i.id).join(','),
                };
              } else {
                return {
                  standardId: item.standardId,
                  standardData: item.standardData,
                };
              }
            }
          );
          // if (isEqual) {
          this.$api.operationOrder.createOrder(this.formData).then((res) => {
            if (res.code === 0) {
              this.$message.success('提交成功');
              this.$router.push({
                name: 'orderList',
              });
            }
          });
          // } else {
          //   this.$message.warning('有指标项，没选');
          // }
        } else {
          this.$message.warning('请检查网站基本信息');
          return false;
        }
      });
    },
    handleDisable() {
      if (this.$route.query.type === 'detail') {
        return false;
      } else {
        return true;
      }
    },
    // viewTable() {
    //   this.dialogVisible = true;
    //   this.dialogType = 'view';
    //   this.$nextTick(() => {
    //     this.tableData.forEach((row) => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //     this.selectAll(this.tableData);
    //   });
    // },
    // 获取栏目
    getTableData() {
      let obj = {
        agencyId: '102',
      };
      this.$api.operationOrder.getTableData(obj).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data;
        }
      });
    },
    // 获取订单指标详情
    async getOrderDetail(val, name) {
      let obj = {
        orderCode:
          this.$route.query.orderCode === '' ? '' : this.$route.query.orderCode, //编辑时必填
        auditType: val, //审核方式：0-目录，1-机审，2-人审，3-机审+人审，4-挂起，5-描述
      };
      if (name) {
        obj.auditTypeList = name;
      }
      await this.$api.operationOrder.getOrderDetail(obj).then((res) => {
        if (res.code === 0) {
          if (val === 1) {
            this.treeList1 = res.data.treeList[0].children[0].children;
            // this.tableData1 = res.data.treeList[1].children[0].children;
            // this.tableData2 = res.data.treeList[2].children[0].children;
            // this.tableData3 = res.data.treeList[1].children[1].children;
          } else if (val === 3) {
            this.treeList2 = res.data.treeList[0].children[0].children;
            if (res.data.order) {
              this.formData.orderCode = res.data.order.orderCode;
              this.formData.siteName = res.data.order.siteName;
              this.formData.orderType = res.data.order.orderType;
              this.formData.siteUrl = res.data.order.siteUrl;
              // 机构复现处理
              let arr = this.search(
                this.orgTreeData,
                res.data.order.agencyName
              );
              this.formData.sitesOrgArr = arr;
              this.formData.agencyId = res.data.order.agencyId;
              this.formData.agencyName = res.data.order.agencyName;
              this.formData.agencyCode = res.data.order.agencyCode;
              // 时间复现处理
              this.formData.CheckTimeSelector.splice(
                0,
                1,
                res.data.order.orderStartTime
              );
              this.formData.CheckTimeSelector.splice(
                1,
                1,
                res.data.order.orderEndTime
              );
            }
            // standardDtoList处理
            if (res.data.standardDtoList) {
              this.formData.standardDtoList = res.data.standardDtoList;
            }
          }
          // let arr1 = this.treeList1.flatMap((obj) => {
          //   if (obj.children) {
          //     return obj.children.map((child) => child.id);
          //   } else {
          //     return [];
          //   }
          // });
          // let arr2 = this.tableData1.map((obj) => obj.id);
          // let arr3 = this.tableData2.map((obj) => obj.id);
          // let arr4 = this.treeList2.flatMap((obj) => {
          //   if (obj.children) {
          //     return obj.children.map((child) => child.id);
          //   } else {
          //     return [];
          //   }
          // });
          // let arr5 = this.treeList3.flatMap((obj) => {
          //   if (obj.children) {
          //     return obj.children.map((child) => child.id);
          //   } else {
          //     return [];
          //   }
          // });
          // this.allIdArr1 = [...arr1, ...arr2, ...arr3, ...arr4, ...arr5].sort();
        }
      });
    },
    // 处理树数组
    clearChildren() {
      this.objectArray = this.multipleSelection.map((obj) => {
        if (obj.children) {
          return Object.assign({}, obj, { children: [] });
        }
        return obj;
      });
    },
    cancelClick() {
      this.$refs.multipleTable.clearSelection();
      this.dialogVisible = false;
    },
    saveOnClick() {
      if (this.multipleSelection.length !== 0) {
        this.clearChildren();
        this.multipleSelection = this.objectArray;
        const index = this.formData.standardDtoList.findIndex(
          (obj) => obj.standardId === this.findId
        );
        let obj = {
          standardId: this.findId,
          standardData: this.multipleSelection,
        };
        if (index !== -1) {
          this.$set(this.formData.standardDtoList, index, obj);
        } else {
          this.formData.standardDtoList.push(obj);
        }
        this.dialogVisible = false;
        this.$message.close();
        // const findObj = this.formData.standardDtoList.find(
        //   (obj) => obj.id === this.findId
        // );
        this.$message.success('确认成功!');
      } else {
        this.$message.warning('选择栏目不能为空');
      }
    },
    // 选择栏目
    openSelectColumn(val) {
      this.findId = val;
      if (this.$route.query.type != 'detail') {
        this.dialogType = 'add';
      }
      this.dialogVisible = true;
      const findObj = this.formData.standardDtoList.find(
        (item) => item.standardId === val
      );
      if (findObj) {
        // 存在
        this.$nextTick(() => {
          if (typeof findObj.standardData === 'string') {
            findObj.standardData = findObj.standardData
              .split(',')
              .map((item) => {
                return {
                  id: parseInt(item),
                };
              });
          }
          let rowids = findObj.standardData.map((item) => item.id);
          this.tableData.forEach((item) => {
            if (rowids.includes(item.id)) {
              this.$refs.multipleTable.toggleRowSelection(item);
            }
            if (item.children) {
              item.children.forEach((i) => {
                if (rowids.includes(i.id)) {
                  this.$refs.multipleTable.toggleRowSelection(i);
                }
              });
            }
          });
        });
      } else {
        // this.$nextTick(() => {
        //   this.tableData.forEach((row) => {
        //     this.$refs.multipleTable.toggleRowSelection(row);
        //   });
        //   this.selectAll(this.tableData);
        // });
      }
    },
    selectAll(selection) {
      const isSelect = this.tableData.some((item) => selection.includes(item));
      if (isSelect) {
        selection.forEach((item) => {
          this.selectChildren(item, isSelect);
        });
      } else {
        this.tableData.forEach((item) => {
          this.selectChildren(item, isSelect);
        });
      }
      this.multipleSelection = selection;
    },
    selectChildren(row, selected) {
      if (row.children) {
        row.children.forEach((item) => {
          this.toggleSelection(item, selected);
          this.selectChildren(item, selected);
        });
      }
    },
    selectionChange(selection) {
      this.debounce(this.emitSelectionChange, 100, selection);
    },
    emitSelectionChange(selection) {
      this.multipleSelection = selection;
    },
    toggleSelection(row, select) {
      row &&
        this.$nextTick(() => {
          this.$refs.multipleTable.toggleRowSelection(row, select);
        });
    },
    cancelAll() {
      this.$refs[this.ref] && this.$refs[this.ref].clearSelection();
    },
    // 防抖
    debounce(fun, wait, params) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(fun, wait, params);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-header {
  width: 100%;
  height: 300px;
  padding: 15px;
  background-color: #fff;
  .main-header-top {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .icon-left {
      width: 3px;
      height: 15px;
      margin-right: 10px;
      background: url('~@/assets/dot.png') center center no-repeat;
    }
    .icon-right {
      color: #1e283c;
      font-size: 18px;
    }
  }
}
.message-div6 {
  width: 1030px;
  height: 720px;
  background: url('~@/assets/message6.png') center center no-repeat;
  background-size: 100% 100%;
}
.message-div7 {
  width: 1030px;
  height: 100px;
  background: url('~@/assets/message7.png') center center no-repeat;
  background-size: 100% 100%;
}
::v-deep .el-form .el-form-item__label {
  font-size: 14px;
  line-height: 20px;
  color: #1e283c;
}
.main-content {
  margin-top: 20px;
}

.machine-main {
  width: 100%;
  height: 100%;
}
.first-content {
  width: 100%;
  background-color: #fff;
  padding: 20px;
}
.second-content {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
}
::v-deep .el-table .grey {
  background: #d0d5e7;
}
::v-deep .el-table tbody tr:hover > td {
  background-color: #d0d5e7 !important;
}
// 如果td没有颜色，可以去掉，这里加上保险
::v-deep .el-table__body tr.hover-row > td {
  background-color: #d0d5e7;
}
::v-deep .el-dialog .el-dialog__body {
  padding: 0 10px;
}
::v-deep .el-range-editor.is-disabled input {
  color: #333;
}
::v-deep .is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff !important;
}
::v-deep .is-disabled.is-checked .el-radio__inner {
  background-color: #409eff !important;
}
::v-deep .el-radio__input.is-disabled + span.el-radio__label {
  color: #333;
}
</style>
<style  scoped>
.table-css >>> .el-button--small {
  padding: 0;
}
</style>